.company-tab-modal {
  min-height: 400px;
}

.projectStatusFilter {
  width: 220px;
}

.projects-map.map-wrapper {
  width: 100%;
  height: 400px;

  .map-container {
    width: 100%;
    height: 100%;
  }
}

.background-loading {
  position: fixed;
  z-index: 10;
  right: 30px;
  bottom: 30px;
  background: white;
  padding: 15px 30px;
  padding-left: 15px;
  box-shadow: 3px 3px 4px #00000042;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  pointer-events: none;

  strong {
    margin-left: 15px;
  }
}
