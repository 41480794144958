.Import {

  .upload-button {
    display: none;
  }

  .upload-label {
    border: 2px solid;
    margin: 15px;
    padding: 8px 15px;
    cursor: pointer;
  }

  .cancel {
    border: none;
    background: none;
  }

  .upload {
    margin: 15px;
    padding: 10px 15px;
    border: 2px solid;
    background: none;
  }

  .link {
    color: orange;
    font-weight: bold;
  }

}
