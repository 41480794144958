/* Identifies all clickable elements. Must be in global scope */
.clickable { cursor: pointer; }

/* Used mostly to float icons right on rows of text*/
.floatright { float:right; }

/* This is used in listings where a looped value needs to be visually separated */
.rightPad { margin-right: 3px; }

.pseudolink {
  text-decoration: underline;

  &.active {
    color: green;
  }
}

.typicalBox {
  padding: 10px;
  border: 2px solid #eee;
  border-radius: 3px;
}

// Avainia custom select container style
.select-container {
  display: flex;
  width: 100%;
  max-width: 330px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  margin-bottom: 1em;
  justify-content: left;

  @media only screen and (max-width: 1270px) {
    max-width: 330px;
  }
}

/**
 * Styling for fileinput, used e.g. in userphoto & product photo inputs
 * TODO: move to its own component
 */
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  label {
    padding: 0;
    margin: 0;
  }
}

.inputfile + label {
  display: inline-block;
  text-align: center;
  max-width: 160px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  color: $black;
  border: 2px solid #56276a;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;

  &.wide {
    max-width: 500px;
  }

  svg {
    margin: 0 4px 4px 0;
  }
}

.inputfile:focus + label,
.inputfile + label:hover {
  border-color: #56276a;
  background: #56276a;
  color: $text-muted;
}

.remove-photo-button {
  max-width: 160px;

  svg {
    margin: 0 4px 4px 0;
  }
}

/**
 * TODO: move to its own component (?)
 */
.document-thumbnail {
  margin: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 25em;
  min-height: 100px;
  min-width: 100px;
}

@media only screen and (max-width: 920px) {
  .document-thumbnail {
    margin: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 15em;
    min-height: 100px;
    min-width: 100px;
  }

  .mobile-document-view.modal {
    width: 90%;
  }
}

/**
 * TODO: move to its own component
 */
#ModalViewDocument {
  .toast-body {
    height: 95%;
    background-color: #fff;
    .document-thumbnail {
      height: 60%;
    }
  }
}

/**
 * TODO: move to its own component
 */
#user-photo-modal {
  #user-photo-modal-preview {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
  }

  #userphoto-preview-label {
    margin-top: 10px;
    font-size: 20px;
  }
}
