.image-thumbnail {
  border: 1px solid #555;
  margin: 5px;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.image-list-thumbnail {
  width: 100%;
  height: 100px;
  border: 1px solid #555;
  margin: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
