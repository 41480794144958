.documenttypes-top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .companies-buttons {
    > * {
      margin: 8px;
    }
  }
}

.company-buttons, .user-buttons {
  button {
    margin: 0 8px;
  }
}

.new-password{
  padding-right: 8px;
  width: 75%;
}

.documenttype-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}



.tinyform {
  padding: 8px 18px;
}

.tinyform input, .tinyform select, .tinyform textarea, .tinyform .select-container {
    max-width: 330px;
}
