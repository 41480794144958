.modal-document-body {
  max-width: 100%;
  width: 100%;
  overflow-y: auto;
  max-height: unset;
}

.modal-document-footer {
  position: absolute;
  bottom: 1px;
  background-color: white;
}

.modal-document-buttons {
  height: 95px;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.mobile-document-view {
  .modal-dialog {
    height: auto;

    .modal-content {
      overflow-y: auto;
      max-height: calc(100% - 60px);
      height: 90vh;

      .modal-body {
        max-height: 100%;

        .toast {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

#ModalViewDocument .toast-body .document-thumbnail {
  height: 50%;
}

.replace-file.label.label-select-files {
  margin: 20px 0;
}

.d-flex.modal-buttons.btn-group {
  flex-wrap: wrap;
}

.d-flex.modal-buttons.btn-group button.btn.btn-primary {
  margin-bottom: 0.2rem;
}

.d-flex.modal-buttons.small.btn-group > button.btn.btn-primary {
  width: 25%;
}

#ModalViewDocument {
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
  height: 100%;

  .group-wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    .toast-wrapper {
      position: relative;
      border: none;
      height: 100%;

      .toast {
        margin-bottom: 0rem;
        border-radius: 0;

        .toast-body .document-thumbnail {
          height: 50%;
        }
      }
    }
  }
}
