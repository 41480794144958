.project-subtypes-select {
  .select-container {
    max-width: 80vw;
  }
}

.tree-menu-list {
  cursor: pointer;

  .tree-0 {
    padding-left: 0rem !important;
  }

  .tree-1, .tree-2, .tree-3, .tree-4, .tree-5, .tree-6, .tree-7, .tree-8 {
    margin-left: 2rem;
    padding-left: 2rem;
  }

  svg.Icon {
    color: #4a78ef;
  }

  .children {
    border-left: 1px solid #999;
    margin-left: 15px;
  }
}

.permissions-for-company {
  max-height: 200px;
  overflow-y: auto;
  border: 1px dashed;
  margin: 10px 0px 50px 0px;
  padding: 15px;

  .informational-grid {
    margin: 0 auto;
    border: 1px solid;

    td {
      padding: 0px 5px;
      border: 1px solid;

      svg {
        height: 20px;
      }
    }
  }
}